import React, { useState, useEffect } from "react";

export const Typewriter = ({ text, speed = 100 }) => {
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    let index = 0;

    const typeEffect = () => {
      if (index < text.length) {
        setDisplayedText(text.substring(0, index + 1));
        index++;
      }
    };

    const intervalId = setInterval(typeEffect, speed);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [text, speed]);

  return <>{displayedText}</>;
};
