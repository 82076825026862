import React, { useEffect } from "react";
import { SetDocumentTitleAndMeta } from "../Utils";
import { Row, Col } from "react-bootstrap";
import StandardContent from "../comp/StandardContent";
import "./Contact.scss";

import ContactForm from "../comp/Contact";
import Checkmark from "../icons/contact/check.svg";

const Contact = () => {
  useEffect(() => SetDocumentTitleAndMeta("contact"));

  return (
    <div className="contact-page">
      <div className="page-top-bg" />
      <StandardContent>
        <Row>
          <Col md={6} className="pe-4">
            <h2 className="pb-2">Exceed Quality Expectations with Quaind.</h2>
            <p className="lead">
              Quaind seamlessly evaluates your website's visual design and
              quickly detects broken pages. Beyond visuals, it analyzes text
              content, performs contextual assessments, and identifies
              grammatical errors. Here are four compelling reasons why Quaind is
              the ultimate automation platform for your needs.
            </p>
            <p>
              <img src={Checkmark} alt={""} />
              &nbsp;&nbsp;Employ our in-depth, close-to-human visual analysis of
              your website alongside comprehensive text analysis, including
              grammar checks.
            </p>
            <p>
              <img src={Checkmark} alt={""} />
              &nbsp;&nbsp;Benefit from our no-code, automated quality assurance
              workflows, available 24/7.
            </p>
            <p>
              <img src={Checkmark} alt={""} />
              &nbsp;&nbsp;Boost customer experience by catching bugs in the user
              interface early, before they reach production.
            </p>
            <p>
              <img src={Checkmark} alt={""} />
              &nbsp;&nbsp;Seamlessly integrate Quaind with the tools you already
              use.
            </p>
          </Col>
          <Col md={6} className="contact-form">
            <ContactForm />
          </Col>
        </Row>
      </StandardContent>
    </div>
  );
};

export default Contact;
