import React, { useEffect } from "react";
import { Typewriter } from "../comp/Typewritter";
import { SetDocumentTitleAndMeta, Url } from "../Utils";
import { Row, Col, Button, Card } from "react-bootstrap";
import "aos/dist/aos.css"; // Import AOS styles
import AOS from "aos"; // Import AOS for animations
import StandardContent from "../comp/StandardContent";
import "./Landing.scss";
import LandingImg1 from "../images/landing1.svg";
import LandingImg2 from "../images/landing2.png";
import LandingImg3 from "../images/landing3.jpg";

const Landing = () => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  useEffect(() => SetDocumentTitleAndMeta("landing"));

  return (
    <div className="landing-page">
      <div className="page-top-bg">
        <div className="dotted-bg"></div>
      </div>
      <StandardContent className="container">
        <Row className="align-items-top my-5 first" data-aos="fade-up">
          <Col md={6} className="pb-5">
            <span class="badge rounded-pill bg-light text-dark">
              <Typewriter text="Powered by Artificial Intelligence" />
            </span>
            <h1 className="mt-2 pb-2 pt-2">Transform Quality Assurance</h1>
            <p className="lead">
              Meet the All-in-One Quality Assurance Automation Platform. Release
              faster and ship high-quality user interface with Quaind’s fully
              automated workflows.
            </p>
            <div>
              <Button
                className="me-2 btn-lg"
                variant="outline-secondary"
                href={"#learn-more"}
              >
                Learn more
              </Button>
              <Button
                className="btn-lg"
                variant="primary"
                href={Url("GetDemo")}
              >
                Get a demo
              </Button>
            </div>
          </Col>
          <Col md={6} data-aos="fade-left">
            <img
              src={LandingImg1}
              alt="Meet Quaind, the All-in-One quality assurance automation platform."
              className="img-fluid rounded"
            />
          </Col>
        </Row>
        <Row className="align-items-center my-5 pb-5" data-aos="fade-up">
          <Col md={6} data-aos="fade-right" className="order-2 order-md-1">
            <img
              src={LandingImg2}
              alt="With Quaind you can release faster with confidence."
              className="img-fluid rounded"
            />
          </Col>
          <Col md={6} className="pb-5 order-1 order-md-2">
            <h2 className="pb-2">Release Faster with Confidence.</h2>
            <p className="lead">
              Product testing cicles often demand substantial amount of
              resouces. Tesing is a crucial but it is oftentimes resource
              intensive. Quaind changes the game with fully automated, no-code
              workflows that empower teams to complete test cycles faster. With
              just a few clicks, you can streamline the entire UI quality
              assurane process, ensuring your product moves from testing to
              release faster—without compromising on quality. <br />
              <br />
              Our 24/7 continuous testing ensures you catch visual regressions
              early and respond swiftly. With real-time monitoring and instant
              alerts, you can address issues before they impact your users. Stay
              ahead of your competition by leveraging Quaind's cutting-edge AI
              powered technology to maintain a flawless user experience, reduce
              customer churn, and release confidently—every time.
            </p>
            <div>
              <Button
                className="me-2 btn-lg"
                variant="primary"
                href={"#learn-more"}
              >
                Read more
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center my-5" data-aos="fade-up">
          <Col md={6} className="pb-5">
            <h2 className="pb-2">
              Deliver a High-Quality User Interface, Every Time.
            </h2>
            <p className="lead">
              Bugs in the UI can significantly impact customer satisfaction.
              Leveraging AI, Quaind can perform continuous quality assurance
              tests, conducting in-depth visual and contextual analysis of your
              website. Our platform is capable of detecting and alerting
              problems before they even reach production.
              <br />
              <br />
              Take advantage of our fully automated, no-code quality assurance
              workflows. Ship a bug-free UI to your end users and boost customer
              satisfaction.
            </p>
            <div>
              <Button
                className="me-2 btn-lg"
                variant="outline-primary"
                href={"#learn-more"}
              >
                Learn more
              </Button>
              <Button
                className="me-2 btn-lg"
                variant="primary"
                href={Url("GetDemo")}
              >
                Schedule demo
              </Button>
            </div>
          </Col>
          <Col md={6} data-aos="fade-left" className="pb-5">
            <img
              src={LandingImg3}
              alt="Landing Visual"
              className="img-fluid rounded"
            />
          </Col>
        </Row>
      </StandardContent>

      <StandardContent className="container">
        <Row className="align-items-center mt-5">
          <h2 id="learn-more" className="text-center">
            Put Artificial Intelligence to Work.
          </h2>
          <p className="text-center lead">
            Quaind exploits state-of-the-art machine learning methods to provide
            the best assessments of the quality and appearance of your website.
          </p>
        </Row>
        <Row className="mt-2">
          <Col sm={4} data-aos="zoom-in" className="pb-2">
            <Card body>
              <p className="header lead">
                Visual Tests That Go Beyond The Simple Image Comparison
              </p>
              <p className="description lead">
                Quaind's advanced visual testing engine goes beyond basic image
                comparison methods. It employs cutting-edge ML techniques to
                provide the best recommendations for your website by identifying
                regions of interest that may be broken.
              </p>
              <a href={Url("GetDemo")} class="card-link">
                Get started
              </a>
            </Card>
          </Col>
          <Col sm={4} data-aos="zoom-in" className="pb-2">
            <Card body>
              <p className="header lead">World-Class Contextual Analysis</p>
              <p className="description lead">
                {" "}
                Quaind automatically identifies grammar problems, detects broken
                links, and highlights inconsistencies or errors within the text.
                By analyzing the context in which text appears, Quaind ensures
                your content is polished, professional, and optimized for both
                user experience and search engines.
              </p>
              <a href={Url("GetDemo")} class="card-link">
                Get started
              </a>
            </Card>
          </Col>
          <Col sm={4} data-aos="zoom-in" className="pb-2">
            <Card body>
              <p className="header lead">Achieve More with Less Resources</p>
              <p className="description lead">
                Let the QA team focus on what cannot be automated, while Quaind
                takes care of the heavy lifting for websites, including visual
                inspection and contextual analysis. Free up your team and let
                them concentrate on higher-level, strategic quality assurance
                tasks that truely require human insight.
              </p>
              <a href={Url("GetDemo")} class="card-link">
                Get started
              </a>
            </Card>
          </Col>
        </Row>
        <Row className="align-items-center mt-5">
          <Col sm={12} className="d-flex justify-content-center">
            <Button className="btn-lg" variant="primary" href={Url("GetDemo")}>
              Get started now
            </Button>
          </Col>
        </Row>
      </StandardContent>
    </div>
  );
};

export default Landing;
