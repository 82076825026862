
import React, { useEffect } from 'react'
import ReactGA from 'react-ga'

const GoogleAnalyticsPageView = (props) => {

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    });

    return (<></>);
}

export default GoogleAnalyticsPageView;
