import React, { useEffect } from "react";
import StandardContent from "../comp/StandardContent";
import { SetDocumentTitleAndMeta } from "../Utils";
import { GetText } from "../Lang";
import { Row, Col } from "react-bootstrap";

const PageNotFound = () => {
  useEffect(() => SetDocumentTitleAndMeta("pageNotFound"));
  return (
    <>
      <div className="page-top-bg" />
      <StandardContent>
        <Row className="text-center">
          <Col>
            <h1 className="display-1 text-danger">404</h1>
            <p className="lead">{GetText("pageNotFound", "pageNotFound")}</p>
          </Col>
        </Row>
      </StandardContent>
    </>
  );
};

export default PageNotFound;
