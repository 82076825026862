import React, { useEffect } from "react";
import "./index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "./common.scss";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Product from "./pages/Product";
import Features from "./pages/Features";
import Navigation from "./comp/Navigation";
import Footer from "./comp/Footer";
import PageNotFound from "./pages/PageNotFound";
import CookieToast from "./comp/CookieToast";
import Contact from "./pages/Contact";
import UseCases from "./pages/UseCases";
import ThankYou from "./pages/ThankYou";
import GoogleAnalyticsPageView from "./comp/GoogleAnalyticsPageView";
import ReactGA from "react-ga";
import Landing from "./pages/Landing";

function App() {
  // Initialize Google Analytics
  useEffect(() => {
    ReactGA.initialize("UA-186129863-1", { testMode: false });
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            path={`/`}
            element={
              <>
                <GoogleAnalyticsPageView />
                <CookieToast />
                <Navigation />
                <Landing />
                <Footer />
              </>
            }
          />
          {false && (
            <Route
              path={`/Product`}
              element={
                <>
                  <GoogleAnalyticsPageView />
                  <CookieToast />
                  <Navigation productActive="active" />
                  <Product />
                </>
              }
            />
          )}
          {false && (
            <Route
              path={`/UseCases/`}
              element={
                <>
                  <GoogleAnalyticsPageView />
                  <CookieToast />
                  <Navigation usesCasesActive="active" />
                  <UseCases />
                  <Footer />
                </>
              }
            />
          )}
          {false && (
            <Route
              path={`/Features/`}
              element={
                <>
                  <GoogleAnalyticsPageView />
                  <CookieToast />
                  <Navigation featuresActive="active" />
                  <Features />
                  <Footer />
                </>
              }
            />
          )}
          <Route
            path={`/GetDemo/`}
            element={
              <>
                <GoogleAnalyticsPageView />
                <CookieToast />
                <Navigation contactActive="active" />
                <Contact />
                <Footer />
              </>
            }
          />
          {false && (
            <Route
              path={`/ThankYou/`}
              element={
                <>
                  <GoogleAnalyticsPageView />
                  <CookieToast />
                  <Navigation />
                  <ThankYou />
                  <Footer />
                </>
              }
            />
          )}
          <Route
            path="*"
            element={
              <>
                <GoogleAnalyticsPageView />
                <Navigation />
                <PageNotFound />
                <Footer />
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
