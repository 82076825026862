import React from "react";

const Footer = (props) => {
  //const footerBg = !props.hideFooterBackground;
  const footerBg = false;
  return (
    <>
      {footerBg && <div className="footer-bg"></div>}
      <section className="footer">
        <p
          className={`copyright-note` + (footerBg ? ` light` : ``)}
          dangerouslySetInnerHTML={{
            __html: new Date().getFullYear() + " &copy; All rights reserved.",
          }}
        ></p>
      </section>
    </>
  );
};

export default Footer;
