import React from 'react'
import ErrorIcon from '../icons/error.png'

const Error = (props) => {
    const {message} = props;
    return (
        <div className="text-center">
            <img style={{"maxHeight": "100px"}} src={ErrorIcon} alt={message}/>
            <h3 className="mt-2">{message}</h3>
        </div>
    );
}

export default Error;