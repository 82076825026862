import React, { useState } from "react";
import { SendHttpRequest } from "../Utils";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Error from "./Error";
import { GetText } from "../Lang";
import ReactGA from "react-ga";
import { Row, Col } from "react-bootstrap";

const handleSubmit = (event, data, setLoading, setThank, setError) => {
    setLoading(true);
    event.preventDefault();
    ReactGA.event({
        category: "Contact",
        action: "Submitted Form",
    });
    SendHttpRequest("Contact", {
        method: "POST",
        body: JSON.stringify(data),
    })
        .then(function (data) {
            data.errorCode ? setError(true) : setThank(true);
            setLoading(false)
        })
        .catch(() => { setError(true); setLoading(false) });
};

const ThankYou = () => {
    return (
        <>
            <h2>{GetText("thankYou", "header")}</h2>
            <p className="lead">{GetText("thankYou", "explanation")}</p>
        </>
    );
};

const Contact = () => {
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [company, setCompany] = useState("");
    const [message, setMessage] = useState("");
    const [thank, setThank] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    return (
        <>
            {thank ? (
                <ThankYou />
            ) : error ? (
                <Error message={`Error occured`} />
            ) : (
                <>
                    <Form
                        onSubmit={(event) =>
                            handleSubmit(
                                event,
                                {
                                    email: email,
                                    firstName: firstName,
                                    lastName: lastName,
                                    company: company,
                                    message: message,
                                },
                                setLoading,
                                setThank,
                                setError
                            )
                        }
                    >
                        <Form.Group controlId="formGridEmail" className="mb-4">
                            <Form.Control
                                type="email"
                                required
                                placeholder={`Business Email*`}
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId="formGridEmail" className="mb-4">
                            <Row>
                                <Col md={6}>
                                    <Form.Group controlId="firstName">
                                        <Form.Control
                                            type="text"
                                            required
                                            placeholder="First Name*"
                                            value={firstName}
                                            onChange={(event) => setFirstName(event.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="lastName">
                                        <Form.Control
                                            type="text"
                                            required
                                            placeholder="Last Name*"
                                            value={lastName}
                                            onChange={(event) => setLastName(event.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group controlId="formGridCompany" className="mb-4">
                            <Form.Control
                                type="company"
                                required
                                placeholder={`Company*`}
                                value={company}
                                onChange={(event) => setCompany(event.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId="formGridMessage" className="mb-4">
                            <Form.Control
                                as="textarea"
                                rows="2"
                                placeholder={`Tell us more about your business`}
                                value={message}
                                onChange={(event) => {
                                    setMessage(event.target.value);
                                }}
                            />
                        </Form.Group>
                        <Button
                            disabled={loading}
                            variant="primary"
                            className="block-md float-end"
                            type="submit"
                        >{`Request demo`}</Button>
                    </Form>
                </>
            )}
        </>
    );
};

export default Contact;
